<template>
    <nav
        class="hidden lg:flex flex-1 flex-col"
        aria-label="Sidebar">
        <ul
            role="list"
            class="space-y-1">
            <li
                v-for="item in navigation"
                :key="item.name">
                <NuxtLink
                    :href="item.link"
                    :class="[item.current ? 'bg-gray-50 text-primary hover:text-primary' : 'text-gray-700 hover:bg-gray-50 hover:text-primary', 'group flex gap-x-3 rounded-md p-2 pl-3 text-sm/6 font-semibold']">
                    {{ item.name }}
                </NuxtLink>
                <!--<ul
                    v-if="item.children?.length"
                    role="list"
                    class="mx-2 space-y-1">
                    <li
                        v-for="child in item.children"
                        :key="child.name">
                        <NuxtLink
                            :href="child.link"
                            :class="[child.current ? 'bg-gray-50 text-primary hover:text-primary' : 'text-gray-700 hover:bg-gray-50 hover:text-primary', 'group flex gap-x-3 rounded-md p-2 pl-3 text-sm/6 font-semibold']">
                            {{ child.name }}
                        </NuxtLink>
                    </li>
                </ul>-->
            </li>
        </ul>
    </nav>
    <VForm
        as="div"
        class="lg:hidden">
        <BaseSelect
            v-if="navigation.length"
            v-model="selected"
            class="w-full mb-1">
            <option
                v-for="item in navigation"
                :key="item.name"
                :value="item.link">
                {{ item.name }}
            </option>
        </BaseSelect>
    </VForm>
</template>

<script setup>
import {usePageStore} from "@/store-pinia/page";

const pageStore = usePageStore()
const pageData = computed(() => pageStore.pageData)

const {path} = useRoute()

const selected = computed({
    get: () => path,
    set: (value) => {
        useRouter().push(value)
    }
});

const getHighestParent = (page) => {
    while (page?.attributes?.Ueberseite?.data) {
        page = page.attributes.Ueberseite.data;
    }
    return page;
};

const buildNavigation = (page, parentRoute) => {
    const children = page.attributes?.Unterseiten?.data ?? [];
    return children.map(item => ({
        name: item.attributes?.Titel,
        link: getLink(item, parentRoute),
        current: getLink(item, parentRoute) === path,
        //children: buildNavigation(item, parentRoute + '/' + item.attributes?.url)
    }));
};

const getLink = (page, parentRoute) => {
    return '/' + parentRoute + '/' + page.attributes?.url
};

const navigation = computed(() => {
    /*const highestParent = getHighestParent(pageData.value);
    const highestParentRoute = highestParent.attributes?.url;*/
    const parent = pageData.value.attributes?.Ueberseite?.data;
    const parentRoute = path.split('/').filter(Boolean).slice(0, -1).join('/');
    return buildNavigation(parent, parentRoute);
});
</script>